import React, { lazy, Suspense, useState } from 'react'

const App = lazy(() => import("./components/App"));

function PasswordProtect({ }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const correctPassword = 'PetkoVoivoda'; // Passwort festlegen
    const [passwordIsChecked, setPasswordIsChecked] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setPasswordIsChecked(true);
        const delay = Math.floor(Math.random() * 2000) + 1000;
         setTimeout(() => {
            if (password === correctPassword) {
                setIsAuthenticated(true);
            } else {
                alert('Falsches Passwort!');
            }
            setPasswordIsChecked(false);
        }, delay);
    };

    return (
        <>
            {(!isAuthenticated) ? (
                <div>
                    <h1>Enter the Password:</h1>
                    {!passwordIsChecked ? (
                        <form onSubmit={handleSubmit}>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Passwort"
                            />
                            <button type="submit" disabled={passwordIsChecked}>Submit</button>
                        </form>
                    ) : (
                        <h2>Password is checked...</h2>
                    )}
                </div>
            ) : (
                <Suspense fallback={<div>Loading...</div>}>
                    <App />
                </Suspense>
            )}
        </>
    );
}

export default PasswordProtect;